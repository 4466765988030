import React, { useState } from 'react';
import data from './data.json';
import { TextField, Box, Button, Grid, Card, CardContent, CardMedia, Typography, List, ListItem, ListItemText, Chip, Skeleton, Tooltip   } from '@mui/material';
import './Search.css';
import Logo from './imgs/adsc-logo.png'
import HashTools from './imgs/hashtools.png'

const SerialNumberSearch = () => {
  const [serialNumber, setSerialNumber] = useState('');
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(false); // Added loading state
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState(null);

  const handleChange = (event) => {
    if (event.target.value === '' || (event.target.value > 0 && event.target.value < 3667)) {
      setSerialNumber(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (serialNumber === '') {
        setItem(null);
        setImageLoading(false);
      } else {
    setLoading(true);
    setImageLoading(true);
    const foundItem = data.find((item) => item.serial === parseInt(serialNumber, 10));
    setItem(foundItem);
  
    const preloadedImage = new Image();
    preloadedImage.src = replaceIPFSWithGateway(foundItem.image);
    preloadedImage.onload = () => {
      setImage(preloadedImage.src);
      setImageLoading(false);
    };
  
    setLoading(false);
    }
  };

  function replaceIPFSWithGateway(ipfsLink, gateway = 'https://adsc.infura-ipfs.io/ipfs/') {
    const ipfsProtocol = 'ipfs://';
    if (ipfsLink.startsWith(ipfsProtocol)) {
      const ipfsHash = ipfsLink.slice(ipfsProtocol.length);
      const encodedIpfsHash = ipfsHash.replace(/ /g, '%20');
      return `${gateway}${encodedIpfsHash}`;
    }
    return ipfsLink.replace(/ /g, '%20');
  }

  return (
    <div className="serial-number-search">
       <center style={{marginTop: 50, marginBottom: 40}}>
        <a target='_blank' href='https://afterdarksocial.club'>
        <img src={Logo} width={300}/>
        </a>
       </center>
      <form onSubmit={handleSubmit} >
      <Grid
        container
        alignItems="center"
        sx={{
          maxWidth: 700,
          margin: 'auto',
          justifyContent: { xs: 'center', sm: 'flex-start' },
        }}
      >
      <Grid item xs={12} sm={6} sx={{ mt: { xs: 2, sm: 0 } }}>
          <TextField
            fullWidth
            label="Serial #"
            value={serialNumber}
            onChange={handleChange}
            type="number"
            variant="outlined"
            sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#fff',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#fff',
                  '&.Mui-focused': {
                    color: '#fff',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: { xs: 2, sm: 0 } }}>
              <Box sx={{ml: {xs: 0, sm: 1}}}>
            <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                    height: 55,
                    backgroundColor: '#ed4e4e',
                    color: '#ffffff',
                    '&:hover': {
                    backgroundColor: '#d64747',
                    },
                }}
                >
                Search
                </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {item && (
         <Card sx={{ maxWidth: 700, margin: 'auto', mt: 4, mb: 5, backgroundColor: "#1a1a1a", borderRadius: 5 }}>
         <Grid container spacing={2} alignItems="center">
           <Grid item xs={12} md={12}>
             <Box>
               {imageLoading ? (
                 <Skeleton variant="rectangular" width="100%" height={700} />
               ) : (
                 <img
                   src={image}
                   alt={item.name}
                   style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                 />
               )}
             </Box>
           </Grid>
            <Grid item xs={12} md={12}>
              <CardContent>
                <center>
                <Typography variant="h5" component="h2" gutterBottom>
                  ADSC Fang <span style={{color: "#ff404c"}}>#{item.serial}</span>
                </Typography>

                <Tooltip title={"Lower rank is better"}>
                <Typography variant="h6" gutterBottom>
                🏆 {item.rank} / 3666
                </Typography>
                </Tooltip>

                {/* <a target='_blank' href={"https://hashtools.io/account/" + item.owner} style={{textDecoration: "none"}}>
                    <Chip label={`Owner: ${item.owner}`} variant="outlined" sx={{ mb: 7, mt:1 }} />
                </a> */}

                <Box sx={{mt:3, mb:6}}>

                </Box>

                {/* <Typography variant="subtitle1" gutterBottom>
                  Score: {item.score}
                </Typography> */}
                
                {/* <Typography variant="subtitle1" gutterBottom>
                  Token ID: {item.token_id}
                </Typography> */}
                <Grid container spacing={2}>
                  {item.attributes.map((attribute, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card sx={{ height: '100%', backgroundColor: "#242424" }}>
                        <CardContent>
                          <Typography variant="subtitle2" gutterBottom sx={{color: "#d1d1d1"}}>
                            {attribute.trait_type}
                          </Typography>
                          <Typography variant="body1"  gutterBottom>
                            {attribute.value}
                          </Typography>
                          {/* <Typography variant="body2" color="text.secondary" gutterBottom>
                            Score: {attribute.score.toFixed(2)}
                          </Typography> */}
                          <Tooltip title={attribute.count + " items have this trait"}>
                          <Typography variant="body2"  gutterBottom sx={{color: "#ff404c"}}>
                            {((attribute.count / 3666) * 100).toFixed(2)}%
                          </Typography>
                          </Tooltip>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                </center>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      )}

    {item ? (
    <center style={{marginTop: 5, marginBottom: 200}}>
        <a href={'https://hashtools.io'}>
            <img src={HashTools} width={100}/>
        </a>
    </center>
    ):(
        <center style={{marginTop: 25}}>
        <Typography color={"text.primary"} sx={{maxWidth: 500, color: "#6e6e6e"}}>
                Please enter a serial number for the NFT you would like to view from the After Dark Social collection
            </Typography>
        </center>
    )}

    </div>
  );
};

export default SerialNumberSearch;
