import logo from './logo.svg';
import './App.css';
import SerialNumberSearch from './Search';

function App() {
  return (
    <SerialNumberSearch />
  );
}

export default App;
